
import { defineComponent, getCurrentInstance, onMounted, ref } from "vue";
import { Field, ErrorMessage, FieldArray, useForm } from "vee-validate";
import Yup from "@/bundle/validations/YupExpended";
import SelectResponsables from "@/components/modals/forms/selectResponsables/SelectResponsables.vue";
import {ResponsableTechnique} from "@/core/models/ResponsableTechnique";
import ApiService from "@/core/services/ApiService";
import {AlertMessage} from "@/bundle/AlertMessage";

export default defineComponent({
  name: "add-responsable",
  props: {
    permissionnaireId: { String, required: false}
  },
  components: {
    Field,
    ErrorMessage,
    FieldArray ,
    SelectResponsables
  },
  setup(props) {

    const emitter = getCurrentInstance()?.appContext.config.globalProperties.emitter;
    const vfm = getCurrentInstance()?.proxy?.$vfm;
    const responsable = ref<ResponsableTechnique>({
      email: "",
      nom: "",
      prenom: "",
      telephone: "",
      telephone2: "",
      fonction: "",
      permissionnaire: {id: props.permissionnaireId as string},
      id:undefined
    });
    const eventPersist = ref<boolean>(false);
    const loading = ref<boolean>(false);
    const edit = ref<boolean>(false);
    const btnSubmitText = ref<string>("Ajouter");

     
    let schema = //Schema Responsable Technique
      Yup.object().shape({
          nom : Yup.string().required().min(2).max(60).label("Le nom"),
          prenom : Yup.string().required().min(2).max(60).label("Le prénom"),
          email : Yup.string().required().email().label("L'adresse e-mail"),
          telephone : Yup.string().numerotationGabonaise().required().label("Le numéro de téléphone"),
          telephone2 : Yup.string().notRequired().numerotationGabonaise().label("Le numéro de téléphone"),
          fonction : Yup.string().label("La fonction"),
          id: Yup.mixed().default(undefined),
          permissionnaire: Yup.mixed().default({id: props.permissionnaireId as string})
        });

    const { resetForm, handleSubmit, setValues } = useForm<ResponsableTechnique>({
      validationSchema: schema,
    });

    const title = ref("Ajouter un responsable");

    const selectedResponsables = ref<any>([]);
    const showModal = ref(false);

    const openSelectResponsablesModal = () => {
       emitter.emit('open-select-responsables')
    }

    const closeModal = () => {
      showModal.value = false;
      dismissModal();
    }

    const dismissModal = () => {
      showModal.value = false;
      resetFormAction();
    }

    // Reset the form values
    const resetFormAction = () => {
      resetForm({
        values: {
          id: undefined,
          permissionnaire: {id: props.permissionnaireId as string},
          nom: "",
          prenom: "",
          email: "",
          telephone:"",
          telephone2: "",
          fonction: ""
        },
      });
    }

    const submit = handleSubmit(() => {
      if(eventPersist.value){
        loading.value = true;

        let data: any ;
        data = responsable.value;

        if(edit.value){
          ApiService.put("/api/responsableTechnique/"+responsable.value.id, data)
          .then( ({ data }) => 
            {
              loading.value = false;
                var title = "Modifications"
                var message = "<stong>Félicitation</strong>! Les modifications ont enregistrées!"
                AlertMessage.onSuccess(message, title).then(() => {
                  emitter.emit('update-responsable', data);
                  vfm?.hide('addRespModal');
                });
            })
          .catch((error) => {
            loading.value = false;
              AlertMessage.onError("Désolé, une erreur est intervenue, veuillez essayer de nouveau.")
              return false;
          });
        }else{
          ApiService.post("/api/responsableTechnique/add", data)
          .then( ({ data }) => 
            {
              loading.value = false;
                var title = "Responsable technique enregistrée"
                var message = "<stong>Félicitation</strong>! Vous venez d'enregistrer un responsable technique!"
                AlertMessage.onSuccess(message, title).then(() => {
                  emitter.emit('add-new-responsable', data);
                  vfm?.hide('addRespModal');
                });
            })
          .catch((error) => {
            loading.value = false;
              AlertMessage.onError("Désolé, une erreur est intervenue, veuillez essayer de nouveau.")
              return false;
          });
        }
        

      }else{
        emitter.emit('add-new-responsable', responsable.value);
        vfm?.hide('addRespModal');
      }
    });

    onMounted(() => {
      emitter.on('add-responsables', (data) => {
      });

      emitter.on('open-add-responsable', (data) => {
        edit.value = false;
        btnSubmitText.value = "Ajouter";
        resetFormAction();
        vfm?.show('addRespModal');
        if(data){
          // responsable.value?.permissionnaire.id = props.permissionnaireId as string;
          eventPersist.value = data.persist;
          edit.value = false;
        }
      });

      emitter.on('open-edit-responsable', (data) => {
        edit.value = true;
        btnSubmitText.value = "Modifier";
        vfm?.show('addRespModal');
        if(data){
          // responsable.value?.permissionnaire.id = props.permissionnaireId as string;
          eventPersist.value = data.persist;
          responsable.value = {...data.item};
          // setValues(data.item);
        }
      });

      // emitter.on('open-edit-responsable', (data: ResponsableTechnique) => {
      //   responsable.value = {...data};
      //   vfm?.show('addRespModal');
      // });
    })

    return {
      openSelectResponsablesModal,
      dismissModal,
      submit,
      resetForm,
      closeModal,
      title,
      showModal,
      schema,
      responsable,
      loading,
      btnSubmitText,
      edit
    };
  },
  data(){
    return{
      rp : [
                {
        nom:"",
        prenom:"",
        email: "",
        password:"",
      }
      ]
    }
  },
    methods: {

    addRp(fields,q) 
    {
      if(fields.length <= 2 )
      q({ email: '', nom: '',prenom:'', password:'' })
    },

    removeRp(fields,r,rp) 
    {
      if(fields.length > 1 )
      r(rp)
    },

  }
});
