import { defineComponent, ref, computed, watch,getCurrentInstance,onMounted, onBeforeMount } from "vue";
import { useStore } from "vuex";
import _ from "lodash";
import { ErrorMessage, Field} from "vee-validate";
import { RegexList } from "@/bundle/RegexList";
// import { ResponsableTechnique } from "@/core/models/responsable-technique";
import * as JWT from '@/core/services/JwtService';
import { Actions, Mutations } from "@/store/enums/BackStoreEnum";


export default defineComponent({
  name: "select-responsables",
  components: {ErrorMessage, Field},
  setup(props,context) {

    const emitter = getCurrentInstance()?.appContext.config.globalProperties.emitter;
    const vfm = getCurrentInstance()?.proxy?.$vfm;

    const showModal = ref(false);
    const edit = ref(false);
    const store       = useStore();
    const loading     = ref<boolean>(false);
    const selectedItems = ref<any>([]);
    const responsables = ref<any>([]);
    const searchKey = ref<string>("");

    const title = computed(() => {
          return edit.value ? "Modifier un responsable technique" : "Sélectionnez des responsables";
    });

    const actionButtonText = computed(() => {
          return edit.value ? "Modifier" : "Ajouter";
    });

    const responsablesStore = computed(() => {
      return store.getters.responsables.ResponsableTechnique;
    });

    const responsablesAvailaible = computed(() => {
      var ids = selectedItems.value.map( a => {return a.id});
      if(!responsables.value) return [];
      return responsables.value.filter(e => {
        return !ids.includes(e.id)
      })
    });


    const selectItem = (item) => {
      selectedItems.value.push(item);
    }

    const removeItem = (item) => {
      let index = selectedItems.value.findIndex(x => x.id === item.id)
      if(index !== -1){
        selectedItems.value.splice(index, 1);
      }
    }

    const isSelected = (item) => {
      let index = selectedItems.value.findIndex(x => x.id === item.id);
      if(index !== -1){
        return true;
      }
      return false;
    };

    const searchItems = () => {
      responsables.value = [];

      if(!responsablesStore.value) return;

      responsablesStore.value.forEach(element => {
        let posNom = element.nom.toLowerCase().search(searchKey.value);
        let posPrenom = element.nom.toLowerCase().search(searchKey.value);
        if(posNom !== -1 || posPrenom !== -1){
          responsables.value.push(element);
        }
      });
    }

    const addResponsables = () => {
      emitter.emit('add-responsables', selectedItems.value);
      showModal.value = false;
    }

    const closeModal = () => {
      showModal.value = false;
      selectedItems.value = [];
    }

    onMounted(() => {

      store.dispatch(Actions.GET_RESPONSABLES).then(() => {
        responsables.value = responsablesStore.value;
      });

      emitter.on('open-select-responsables', (data) => {
        selectedItems.value = [...data];
        vfm?.show('selectRespModal')
        edit.value = false
      })
    });

    
    // context.emit(edit.value ? 'updatedStationEvent' : 'addedNewStationEvent', {});
    // vfm?.hide('stationModal')

    return {
      isSelected,
      removeItem,
      selectItem,
      searchItems,
      addResponsables,
      closeModal,
      showModal,
      selectedItems,
      title,
      loading,
      actionButtonText,
      responsables,
      responsablesAvailaible,
      searchKey
    };
  }
});
